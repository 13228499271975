import React, { Component } from "react";
import {
  MDBContainer, MDBNavbar,  MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBNavbarBrand
} from "mdbreact";
import { Link } from 'gatsby';
import CustomNavLink from './customLink';
import { ReactComponent as Logo } from "../images/light-bulb.svg";
import { graphql, useStaticQuery } from 'gatsby';



class NavbarPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      data: props.data,
      nav: props.nav
    }
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidMount() {
    //window.addEventListener('scroll', this.handleScroll);
    if(this.state.nav == true || screen.width < 750) {
      document.getElementById("navbar").style.backgroundColor = "rgba(38, 66, 38, 1)";
    }
  }


  render() {
    return (
      <MDBNavbar dark expand="md" id="navbar" className="scrolling-navbar fixed-top" >
        <MDBContainer>
          <MDBNavbarBrand>
            <strong className="white-text">{this.state.data.title}</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler name="navbar-toggler" onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              {
                this.state.data.link.map(item => {
                  const id = item.text + "Nav"
                  return(
                    <CustomNavLink id={id} to={item.link}>{item.text}</CustomNavLink>
                  );
                })
              }
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <div className="d-flex align-items-center">
                {
                  this.state.data.social.map(socialLink => {
                    return(
                    <CustomNavLink to={socialLink.link}>
                      <MDBIcon fab icon={socialLink.text} className="ml-1" />
                    </CustomNavLink>)
                  })
                }
              </div>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }
}

export default NavbarPage;
