import React, { useEffect } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBBtn, MDBIcon } from "mdbreact";
import { navigate } from "gatsby";


const Footer = ({data}) => {
    useEffect(() => {
        // Update the document title using the browser API
        document.getElementById("bbbDiv").innerHTML = data.bbb;
      });
    return (
        <MDBFooter className="font-small pt-0">
            <MDBContainer>
                <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center">
                    {
                        data.link.map(item => {
                            return(
                                <MDBCol>
                                    <a onClick={() => navigate(item.link)}>
                                    {item.text}
                                    </a>
                                </MDBCol>
                            )
                        })
                    }
                </MDBRow>
                <hr className="rgba-white-light" style={{ margin: "0 15%" }} />
                <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">
                    <MDBCol md="4" sm="8" className="mt-5">
                        <p style={{ lineHeight: "1.7rem" }}>
                            {data.NJHIC}
            </p>
                    </MDBCol>
                    <MDBCol id="bbbDiv" md="4" sm="8" className="mt-5">
                    </MDBCol>
                </MDBRow>
                <MDBRow className="pb-3">
                    <MDBCol md="12">
                        <div className="mb-5 flex-center">
                            {
                                data.social.map(item => {
                                    return(
                                        <a href={item.link} >
                                    <MDBIcon fab icon={item.text} size="lg" className="mx-2 white-text mr-md-4"></MDBIcon>
                                    </a>
                                    );
                                })
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; {new Date().getFullYear()} Copyright: Environmental Approach | Site Designed by <a href="https://bctech.dev/" target="_blank">BC Tech</a>
                </MDBContainer>
            </div>
        </MDBFooter>
    );
}

export default Footer;