import React, { Component } from "react";
import classNames from "classnames";
import { MDBWaves } from 'mdbreact';
import { navigate } from "gatsby";
import './layout.css';

class customLink extends Component {

    state = {
        cursorPos: {}
    }

    handleClick = e => {
        if (!this.props.disabled) {
            e.stopPropagation();
            let cursorPos = {
                top: e.clientY,
                left: e.clientX,
                time: Date.now()
            };
            this.setState({ cursorPos: cursorPos });
        }
    };

    helperClick = (li) => {
        if (li.charAt(0) === "/") {
            navigate(li)
        } else {
            window.location.href = li
        }
    }

    render() {
        const {
            children,
            className,
            disabled,
            location,
            to,
            id,
        } = this.props;

        const { cursorPos } = this.state;

        const classes = classNames(
            "nav-item",
            disabled ? "disabled" : "Ripple-parent",
            location === to && "active",
            className
        );

        return (
            <li
                className={classes}
                onMouseUp={this.handleClick}
                onTouchStart={this.handleClick}
                role="link"
                id={id}
                onClick={() => this.helperClick(to)}
            >
                <span className="nav-link customLink">
                  {children}
                </span>
                {!disabled && <MDBWaves cursorPos={cursorPos} />}
            </li>
        );
    }
}

export default customLink;

