import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './navbar'
import Footer from './footer'
import 'semantic-ui-css/semantic.min.css'
import "./layout.css"
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
{
  strapiNavbar {
    title
    link {
      link
      text
      id
    }
    social {
      text
      link
      id
    }
  }
  strapiFooter {
    bbb
    NJHIC
    link {
      id
      link
      text
    }
    social {
      link
      text
      id
    }
  }    
}
`

const Layout = ({ children, navInfo }) => {
  const {strapiNavbar, strapiFooter} = useStaticQuery(query)
  return(
    <>
      <Navbar data={strapiNavbar} nav={navInfo} />
        {children}
      <Footer data={strapiFooter} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
